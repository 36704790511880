<template>
  <div class="mb-2" v-if="enrollment.length > 0">
    <div class="div-header">
      <b-row class="d-flex align-items-center">
        <b-col class="d-flex align-items-center">
          <b-form-checkbox
            class="input-plan"
            size="lg"
            checked="true"
            disabled
          ></b-form-checkbox>
          <small class="text-muted">{{ enrollment[0].description }}</small>
        </b-col>
        <b-col class="d-flex justify-content-end" md="3">
          <div>
            <span class="h5 text-muted" style="margin-bottom: unset">{{
              toMonetary(enrollment[0].value)
            }}</span>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      class="div-body"
      :class="{ 'border border-danger': errors.includes('enrollment_value') }"
      v-if="enrollment[0].enrollmentinstallment.length > 0"
    >
      <b-form-radio
        v-for="installment in enrollmentInstallmentsById"
        @change="
          $emit('input', {
            group_installment_id: $event,
            id: installment.enrollment_value_id,
          })
        "
        :value="installment.group_enrollment_installment_id"
        :key="installment.id"
        :name="`radio-enrollment-${enrollment[0].id}`"
        ><small>{{ installment.description }}</small></b-form-radio
      >
    </div>    
    <small class="text-danger" v-if="errors.includes('enrollment_value')"
      >Selecione uma opção acima</small
    >    
  </div>
</template>

<script>
export default {
  props:['errors'],  
  computed: {
    data() {
      return this.$store.state.confirmReservation.data;
    },    
    enrollment() {
      return this.data.enrollmentValue;
    },
    enrollmentInstallmentsById(){
      const installments = this.enrollment[0].enrollmentinstallment
      const grouped = _.groupBy(installments, 'group_enrollment_installment_id')
      const norm = []
      _.forEach(Object.keys(grouped),(item)=>{
        const itemById = grouped[item]        
        norm.push({
          id: itemById[0].id,
          enrollment_value_id: itemById[0].enrollment_value_id,
          group_enrollment_installment_id: itemById[0].group_enrollment_installment_id,
          description: itemById[0].description
        })
      })
      return norm
    }    
  },
};
</script>

<style>
</style>